.contact-cards {
    .card-left {
        span {
            color: $brand-link;
        }
    }

    .card {
        a {
            .btn-img-bg {
                background: #896002;

            }
        }
        a:hover .btn-img-bg {
            background: $brand-link;
        }

    }
}