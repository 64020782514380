// passage

.page_5 {
    .passage {
        margin-top: 90px;
    }
}

.page_4 {
    .passage {
    margin-top: 90px;
    padding-bottom: 0;
    }
}

//somnovia-phase2
.project-somnovia.page_4 .passage {
    padding-bottom: 140px;
    margin-bottom: 130px;
}

//reviga-phase2
.project-reviga.page_4 .passage {
    padding-bottom: 140px;
    margin-bottom: 130px;
}

// this is special for liebria project
.project-liebria.page_4 .passage {
    margin-bottom: 90px;
}

.passage.passage-faq-button {
    .passage-link {
        display: flex;
        justify-content: center;
        padding-top: 20px;
    }
}

// anchor helper
.passage-anchor {
    position: relative;
    top: -100px;
}

.passage-link {
    margin-top: 20px;
}

.passage--extra-space-heading {
    h4 {
    margin-bottom: 30px;
    }
}

.passage-background {
    background-color: $copy-background;
}

.small-font {
    p {
        font-size: 12px;
    }
}

.short-padding {
    padding-bottom: 20px !important;
    padding-top: 0px !important;
}

.passage {
    padding-top: 20px;
    padding-bottom: 20px;
    margin: 0 auto;

    @include text-style;

    h2, h3, p {
        @include span-brand;
    }

    .passage-svg-icon {
        height: 250px;
    }

    img, object {
        display: block;
        margin: 80px auto 0px auto;
    }

    object {
        max-width: 370px;
    }

    .passage-txt-citation {
        font-size: 14px;
    }

    .passage-info-subtext {
        p {
            font-size: 12.5px;
            line-height: 17px;
            font-weight: 400;
        }
    }

    .passage-row-line {
        width: 100%;
        height: 2px;
        background-color: $brand-row-separator;
        margin-bottom: 40px;
    }

    .pro-passage {
        h2 {
            font-size: 20px;
            font-weight: 500;
        }
    }

    .page3 {
        margin-top: 50px;
    }

    .padding-normal {
        padding-top: 0px;
        padding-bottom: 80px;
    }
}

.passage-last {
    margin-bottom: 50px;

    h2 {
        font-size: 18px;
    }

    p {
        font-size: 14px;
    }
}

strong {
    font-weight: bold;
}

@include media-breakpoint-up (sm) {
    .page_4,
    .page_5 {
        .passage {
        margin-top: 50px;
        }
    }

    .page_6 {
        .passage {
                padding-top: 0px;
                padding-bottom: 60px;
        }
    }

    .passage {
        padding-bottom: 40px;
        padding-top: 50px;
    }

    .passage-first {
        padding-top: 70px;

    }

    .passage--padding {
        padding-bottom: 60px;
    }
}


@include media-breakpoint-up (md) {
    .passage {
        h2 {
            font-size: 52px;
        }

        h3 {
            font-size: 32px;
        }
    }
}
